import styled from 'styled-components';

import { SIZES } from 'src/helpers/devices';

import { IBorderProps } from './types';

export const RatingCoverageWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  background-color: ${(props) => props.theme.palette.common.white};
  box-shadow: ${(props) => props.theme.boxShadow.module};
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 9;
  padding: 0 20px 15px 20px;
`;

export const Title = styled.p`
  font-size: 20px;
  line-height: 18px;
  color: ${(props) => props.theme.palette.secondary};
  font-family: ${(props) => props.theme.fontFamilies.bold};
`;

export const RatingCoveragesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const RatingCoverageLabel = styled.p`
  font-size: 16px;
  line-height: 22px;
  color: ${(props) => props.theme.palette.common.grey};
  font-family: ${(props) => props.theme.fontFamilies.regular};
`;

export const RatingCoverageNumber = styled.p`
  @media (max-width: ${SIZES.bigDesktop}px) {
    display: flex;
    padding-left: 0;
  }
  display: inline;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  padding-left: 5px;
  color: ${(props) => props.theme.palette.secondary};
  font-family: ${(props) => props.theme.fontFamilies.bold};
`;

export const RealRatingCoverageLabel = styled.p`
  font-size: 16px;
`;

export const RealRatingCoverageNumber = styled.p`
  font-size: 30px;
  font-family: ${(props) => props.theme.fontFamilies.bold};
`;

export const Border = styled.div<IBorderProps>`
  background-color: ${(props) => props.theme.palette.quaternary};
  width: ${(props) => (props.isLast ? 0 : '1px')};
  margin: 0 10px;
`;

export const TitleWrapper = styled.div`
  @media (max-width: ${SIZES.desktop}px) {
    padding: 20px 20px;
  }
  padding: 20px 20px 10px;
`;

export const RatingProviderWrapper = styled.div`
  @media (max-width: ${SIZES.bigDesktop}px) {
    flex-direction: column;
  }
  display: flex;
`;
