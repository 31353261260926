import { stringify } from 'query-string';

import { ICompany } from 'src/types/company';

import { get } from './api';

export const getCompetitors = (companyId: string) => {
  return get(`companies/competitors/${companyId}`);
};

export const getBy = (filter: Partial<ICompany>, limit = 10, offset = 0) => {
  return get(`companies/by?${stringify(filter)}&limit=${limit}&offset=${offset}`);
};
