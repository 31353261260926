import { AnyAction } from 'redux';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import * as dataActions from 'src/redux/actions/data';
import * as userActions from 'src/redux/actions/user';
import * as dataConstants from 'src/redux/constants/data';
import * as userConstants from 'src/redux/constants/user';

import { DataGroup } from 'src/enums/data';
import { IDataItem } from 'src/helpers/types/data';
import IUser from 'src/types/user';
import IUserSettings from 'src/types/userSettings';

import userDataSelector from '../selectors/user/userData';
import { getData } from '../services/data';
import { getIndustryRealScores } from '../services/score';
import { getUserSettings } from '../services/user';

function* initializeExplore() {
  try {
    const user: IUser = yield select(userDataSelector());
    const [industryScores, data, userSettings]: [number[], IDataItem[], IUserSettings] = yield all([
      call(getIndustryRealScores, user.company.industry),
      call(getData, DataGroup.ENVIROMENT, 1),
      call(getUserSettings),
    ]);
    yield put(userActions.initializeExploreViewSucceeded(industryScores, data, userSettings));
  } catch (error: any) {
    yield put(userActions.initializeExploreViewFailed(error.data.message));
  }
}

function* getMoreData(action: AnyAction) {
  try {
    const data: IDataItem[] = yield call(getData, action.group, action.hierarchy);
    yield put(dataActions.onMoreDataSucceded(data));
  } catch (error: any) {
    yield put(dataActions.onMoreDataFailed(error.data.message));
  }
}

export default all([
  takeLatest(userConstants.USER_ON_INITIALIZE_EXPLORE_VIEW, initializeExplore),
  takeLatest(dataConstants.DATA_ON_LOAD_MORE_REQUESTED, getMoreData),
]);
