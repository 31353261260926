import { Skeleton } from '@mui/material';
import styled from 'styled-components';

import { FOOTER_HEIGHT, HEADER_HEIGHT, SIZES } from 'src/helpers/devices';

export const SummaryWrapper = styled.div`
  @media (max-width: ${SIZES.desktop}px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, auto);
    grid-template-areas:
      'score ratingCoverage'
      'insights news'
      'insights news';
  }
  @media (max-width: ${SIZES.mediumTablet}px) {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(4, auto);
    grid-template-areas:
      'score'
      'ratingCoverage'
      'insights'
      'news';
  }
  min-height: calc(100vh - (${HEADER_HEIGHT}px + ${FOOTER_HEIGHT}px));
  background-color: ${(props) => props.theme.palette.septenary};
  display: grid;
  overflow: auto;
  grid-gap: 20px;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(3, auto);
  padding: 20px;
  grid-template-areas:
    'score insights insights news news'
    'ratingCoverage insights insights news news'
    'auto insights insights news news';
`;

export const NewsModuleWrapper = styled.div`
  grid-area: news;
`;

export const ScoreModuleWrapper = styled.div`
  grid-area: score;
  min-width: 312px;
  height: auto;
`;

export const RatingCoverageModuleWrapper = styled.div`
  grid-area: ratingCoverage;
  min-width: 312px;
  height: auto;
`;

export const InsightsModuleWrapper = styled.div`
  grid-area: insights;
`;

export const LoadingSkeleton = styled(Skeleton)`
  transform: none !important;
`;
