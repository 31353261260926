import i18next from 'i18next';
import React from 'react';

import PillarScoreChart from 'src/components/PillarScoreChart';
import { IPillarScoreChartData } from 'src/components/PillarScoreChart/types';
import { pillarScoreTexts } from 'src/helpers/pillarsScores';
import { getPillarScoresByMateriality, getScoreByPillar } from 'src/helpers/scores';

import '../../i18n';
import {
  ComparisonChartWrapper,
  Materiality,
  MaterialityListWrapper,
  MaterialityPillarsWrapper,
  MaterialityWrapper,
  Pillar,
  PillarChartWrapper,
  Title,
} from './styles';
import { IMaterialityComparisonChart } from './types';

const MaterialityComparisonChart = (props: IMaterialityComparisonChart) => {
  const { materialities, selectedPillar, userCompany, competitors, onChangePillar } = props;
  let chartData: IPillarScoreChartData[] = [];
  if (userCompany.lastUpdatedScore) {
    chartData = chartData.concat({
      company: userCompany.name,
      score: getScoreByPillar(selectedPillar, userCompany.lastUpdatedScore),
    });
  }
  chartData = chartData.concat(
    competitors.map((competitor) => ({
      company: competitor.name,
      score: getScoreByPillar(selectedPillar, competitor.lastUpdatedScore!),
    })) || [],
  );

  return (
    <ComparisonChartWrapper>
      <MaterialityListWrapper>
        {materialities.map((materiality) => {
          const materialityPillars = getPillarScoresByMateriality([materiality]);
          return (
            <MaterialityWrapper>
              <Materiality>{i18next.t(`SALES_MATERIALITY:${materiality}`)}</Materiality>
              <MaterialityPillarsWrapper>
                {materialityPillars.map((pillarMetadata) => {
                  return (
                    <Pillar
                      isSelected={selectedPillar === pillarMetadata.Identifier}
                      onClick={() => onChangePillar(pillarMetadata.Identifier)}
                    >
                      {
                        pillarScoreTexts.find(
                          (pillar) => pillar.value === pillarMetadata.Identifier,
                        )!.text
                      }
                    </Pillar>
                  );
                })}
              </MaterialityPillarsWrapper>
            </MaterialityWrapper>
          );
        })}
      </MaterialityListWrapper>
      <PillarChartWrapper>
        <Title> {pillarScoreTexts.find((pillar) => pillar.value === selectedPillar)!.text}</Title>
        <PillarScoreChart chartData={chartData} userCompany={userCompany} pillar={selectedPillar} />
      </PillarChartWrapper>
    </ComparisonChartWrapper>
  );
};

export default MaterialityComparisonChart;
