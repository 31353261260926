import { TabPanelUnstyled, TabsUnstyled } from '@mui/base';
import i18next from 'i18next';
import React, { useState } from 'react';

import { DataGroup } from 'src/enums/data';
import { formatNumber, isNumber } from 'src/helpers/data';
import { getTipIconByType, getTipIconInactiveByType } from 'src/helpers/insights';
import { TipsCategory } from 'src/types/insights';

import './i18n';
import {
  IconScore,
  Tab,
  TabList,
  WrapperIconScore,
  WrapperText,
  Text,
  ValueScore,
  WrapperContent,
  WrapperTable,
  WrapperLoadMore,
  LoadMore,
  WrapperData,
  LabelData,
  ValueData,
  WrapperContentMoreData,
  WrapperMoreData,
  LoadingWrapper,
  LoadingIndicator,
  LoadingMoreDataIndicator,
} from './styles';
import { ITabsScoresModuleProps } from './types';

const TabsScoresModule = (props: ITabsScoresModuleProps) => {
  const { scores, data, onMoreData, handleTrackEvent } = props;
  const [selectedTab, setSelectedTab] = useState<DataGroup>(DataGroup.ENVIROMENT);
  const [currentHierarchy, setCurrentHierarchy] = useState(1);

  const tabs = [
    {
      tabValue: DataGroup.ENVIROMENT,
      text: i18next.t('TABS_SCORE_EXPLORE_MODULE:ENVIRONMENT'),
      icon:
        selectedTab === DataGroup.ENVIROMENT
          ? getTipIconByType(TipsCategory.ENVIRONMENT)
          : getTipIconInactiveByType(TipsCategory.ENVIRONMENT),
      scoreValue: scores.environmentScore,
    },
    {
      tabValue: DataGroup.SOCIAL,
      text: i18next.t('TABS_SCORE_EXPLORE_MODULE:SOCIAL'),
      icon:
        selectedTab === DataGroup.SOCIAL
          ? getTipIconByType(TipsCategory.SOCIAL)
          : getTipIconInactiveByType(TipsCategory.SOCIAL),
      scoreValue: scores.socialScore,
    },
    {
      tabValue: DataGroup.GOVERNANCE,
      text: i18next.t('TABS_SCORE_EXPLORE_MODULE:GOVERNANCE'),
      icon:
        selectedTab === DataGroup.GOVERNANCE
          ? getTipIconByType(TipsCategory.GOVERNANCE)
          : getTipIconInactiveByType(TipsCategory.GOVERNANCE),
      scoreValue: scores.governanceScore,
    },
  ];

  const handleTabChange = (event: React.SyntheticEvent, value: number | string) => {
    setSelectedTab(value as DataGroup);
    handleTrackEvent(
      `Change explore tab to ${tabs.find((element) => element.tabValue === value)?.text}`,
    );
    setCurrentHierarchy(1);
    onMoreData(value as DataGroup, 1);
  };

  const handleMoreData = () => {
    onMoreData(selectedTab as DataGroup, 2);
    if (currentHierarchy === 2) {
      setCurrentHierarchy(1);
      handleTrackEvent(
        `Close more data ${tabs.find((element) => element.tabValue === selectedTab)?.text}`,
      );
    } else {
      setCurrentHierarchy(2);
      handleTrackEvent(
        `Open more data ${tabs.find((element) => element.tabValue === selectedTab)?.text}`,
      );
    }
  };

  return (
    <TabsUnstyled defaultValue={DataGroup.ENVIROMENT}>
      <TabList>
        {tabs.map((element) => {
          return (
            <Tab
              value={element.tabValue}
              data-testid={`tab-tabsscores-module-explore-page-${element.tabValue}`}
              isSelected={selectedTab === element.tabValue}
              onChange={handleTabChange}
            >
              <WrapperIconScore
                data-testid={`icon-score-tabsscores-module-explore-page-${element.tabValue}`}
              >
                <IconScore src={element.icon} />
              </WrapperIconScore>
              <WrapperText>
                <Text
                  data-testid={`label-score-tabsscores-module-explore-page-${element.tabValue}`}
                >
                  {element.text}
                </Text>
                <ValueScore
                  data-testid={`value-score-tabsscores-module-explore-page-${element.tabValue}`}
                >
                  {element.scoreValue}
                </ValueScore>
              </WrapperText>
            </Tab>
          );
        })}
      </TabList>
      <WrapperTable>
        <TabPanelUnstyled
          data-testid={`tab-selected-tabsscores-module-explore-page-${selectedTab}`}
          value={selectedTab}
        >
          <WrapperContent>
            {data.filter((element) => element.hierarchy === 1 && element.group === selectedTab)
              .length === 0 && (
              <LoadingWrapper data-testid={`loading-first-hierarchy-tabsScores-explore-page`}>
                <LoadingIndicator />
              </LoadingWrapper>
            )}
            {data
              .filter((element) => element.hierarchy === 1 && element.group === selectedTab)
              .map((data) => (
                <WrapperData>
                  <LabelData
                    data-testid={`label-tab-first-hierarchy-selected-tabsscores-module-explore-page-${data.identifier}`}
                  >
                    {i18next.t(`TABS_SCORE_EXPLORE_MODULE:${data.identifier}`)}
                  </LabelData>
                  <ValueData
                    data-testid={`value-tab-first-hierarchy-selected-tabsscores-module-explore-page-${data.identifier}`}
                  >
                    {isNumber(data.value) ? formatNumber(data.value) : data.value} {data.unit}
                  </ValueData>
                </WrapperData>
              ))}
          </WrapperContent>
          {currentHierarchy === 2 && (
            <WrapperContentMoreData>
              {data
                .filter((element) => element.hierarchy === 2 && element.group === selectedTab)
                .map((data) => (
                  <WrapperMoreData>
                    <LabelData
                      data-testid={`label-tab-second-hierarchy-selected-tabsscores-module-explore-page-${data.identifier}`}
                    >
                      {i18next.t(`TABS_SCORE_EXPLORE_MODULE:${data.identifier}`)}
                    </LabelData>
                    <ValueData
                      data-testid={`value-tab-second-hierarchy-selected-tabsscores-module-explore-page-${data.identifier}`}
                    >
                      {isNumber(data.value) ? formatNumber(data.value) : data.value} {data.unit}
                    </ValueData>
                  </WrapperMoreData>
                ))}
            </WrapperContentMoreData>
          )}
        </TabPanelUnstyled>
        <WrapperLoadMore>
          <LoadMore
            data-testid={`load-more-button-tabsscores-module-explore-page`}
            onClick={handleMoreData}
          >
            {currentHierarchy === 2
              ? i18next.t('TABS_SCORE_EXPLORE_MODULE:HIDDEN_MORE')
              : i18next.t('TABS_SCORE_EXPLORE_MODULE:LOAD_MORE')}
          </LoadMore>
        </WrapperLoadMore>
        {data.filter((element) => element.hierarchy === 2 && element.group === selectedTab)
          .length === 0 &&
          currentHierarchy === 2 && (
            <LoadingWrapper data-testid={`loading-second-hierarchy-tabsScores-explore-page`}>
              <LoadingMoreDataIndicator />
            </LoadingWrapper>
          )}
      </WrapperTable>
    </TabsUnstyled>
  );
};

export default TabsScoresModule;
