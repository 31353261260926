import { routerMiddleware } from 'connected-react-router';
import { applyMiddleware, createStore, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';

import history from 'src/helpers/history';

import analyticsMiddleware from './middlewares/analytics.middleware';
import cookiesMiddleware from './middlewares/cookies.middleware';
import redirectMiddleware from './middlewares/redirect.middleware';
import reducers from './reducers';
import { ICompanyReducerState } from './reducers/company';
import { IDataReducerState } from './reducers/data';
import { IInisightsReducerState } from './reducers/insights';
import { INewsReducerState } from './reducers/news';
import { IScoreReducerState } from './reducers/score';
import { IUserReducerState } from './reducers/user';
import { IUserMessageReducerState } from './reducers/userMessage';
import rootSaga from './sagas';

export const sagaMiddleware = createSagaMiddleware();

export interface IReduxState {
  company: ICompanyReducerState;
  user: IUserReducerState;
  news: INewsReducerState;
  insights: IInisightsReducerState;
  data: IDataReducerState;
  userMessage: IUserMessageReducerState;
  score: IScoreReducerState;
}

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  reducers,
  /* preloadedState, */
  composeEnhancers(
    applyMiddleware(
      routerMiddleware(history),
      analyticsMiddleware,
      cookiesMiddleware,
      redirectMiddleware,
      sagaMiddleware,
    ),
  ),
);

sagaMiddleware.run(rootSaga);

export default store;
