import styled from 'styled-components';

import { SIZES } from 'src/helpers/devices';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  background-color: ${(props) => props.theme.palette.common.white};
  box-shadow: ${(props) => props.theme.boxShadow.module};
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 9;
`;

export const ScoresWrapper = styled.div`
  @media (max-width: ${SIZES.desktop}px) {
    justify-content: space-evenly;
  }
  height: 100%;
  display: flex;
  justify-content: space-between;
  padding: 20px 20px 0;
`;

export const Score = styled.div`
  @media (max-width: ${SIZES.desktop}px) {
    flex-direction: row;
    align-items: center;
  }
  display: flex;
  padding-bottom: 15px;
  flex-direction: column;
`;

export const ScoreLabel = styled.p`
  @media (max-width: ${SIZES.desktop}px) {
    padding: 0 10px;
  }
  font-size: 20px;
  line-height: 18px;
  padding-bottom: 10px;
  color: ${(props) => props.theme.palette.secondary};
  font-family: ${(props) => props.theme.fontFamilies.bold};
`;

export const ScoreNumber = styled.p`
  max-width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 30px;
  line-height: 38px;
  color: ${(props) => props.theme.palette.secondary};
  font-family: ${(props) => props.theme.fontFamilies.bold};
  text-align: center;
`;

export const RealScoreLabel = styled.p`
  font-size: 16px;
`;

export const RealScoreNumber = styled.p`
  font-size: 30px;
  font-family: ${(props) => props.theme.fontFamilies.bold};
`;

export const Border = styled.div`
  border-left: 1px solid ${(props) => props.theme.palette.quaternary};
  margin-bottom: 15px;
`;
