import { ReactSVG } from 'react-svg';
import styled from 'styled-components';

import { FOOTER_HEIGHT, HEADER_HEIGHT, SIZES } from 'src/helpers/devices';

import { ILoadMoreButtonProps } from './types';

export const NewsWrapper = styled.div`
  background-color: ${(props) => props.theme.palette.septenary};
  min-height: calc(100vh - (${HEADER_HEIGHT}px + ${FOOTER_HEIGHT}px));
  overflow: auto;
`;

export const WrapperText = styled.div`
  @media (max-width: ${SIZES.mediumTablet}px) {
    flex-direction: column;
    align-items: flex-start;
    margin-right: 20px;
  }
  flex: 9;
  display: flex;
  align-items: flex-end;
`;

export const Title = styled.p`
  font-size: 28px;
  line-height: 40px;
  color: ${(props) => props.theme.palette.secondary};
  font-family: ${(props) => props.theme.fontFamilies.bold};
`;

export const HeaderNewsWrapper = styled.div`
  grid-area: headerNews;
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  border-bottom: 1px solid ${(props) => props.theme.palette.quinternary};
  padding: 0 0 10px;
`;

export const HeaderRatingNewsWrapper = styled.div`
  grid-area: headerFeaturedNews;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0 10px;
  border-bottom: 1px solid ${(props) => props.theme.palette.quinternary};
`;

export const NewsGrid = styled.div`
  @media (max-width: ${SIZES.mediumTablet}px) {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(4, auto);
    grid-template-areas: 'headerFeaturedNews' 'bodyPageFeaturedNews' 'headerNews' 'bodyPageNews';
  }
  display: grid;
  grid-gap: 30px;
  overflow: auto;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, auto);
  padding: 20px;
  grid-template-areas:
    'headerNews headerNews headerFeaturedNews'
    'bodyPageNews bodyPageNews bodyPageFeaturedNews';
`;

export const WrapperRatingNews = styled.div`
  @media (max-width: ${SIZES.mediumTablet}px) {
    margin-bottom: 20px;
  }
  grid-area: bodyPageFeaturedNews;
  display: flex;
  flex-direction: column;
`;

export const WrapperNews = styled.div`
  grid-area: bodyPageNews;
  border-radius: 10px;
  background-color: ${(props) => props.theme.palette.common.white};
  box-shadow: ${(props) => props.theme.boxShadow.module};
  box-sizing: border-box;
  padding: 0 20px;
`;

export const WrapperButtonLoadMore = styled.div`
  padding: 10px 0;
  height: 40px;
  border-top: 1px solid ${(props) => props.theme.palette.quaternary};
`;

export const ButtonLoadMore = styled.button<ILoadMoreButtonProps>`
  width: 100%;
  height: 100%;
  border-radius: 6px;
  color: ${(props) => props.theme.palette.secondary};
  cursor: ${(props) => (props.disabled ? 'auto' : 'pointer')};
  background-color: ${(props) => props.theme.palette.quinternary};
  font-size: 16px;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  color: ${(props) => props.theme.palette.lightSecondary};
  border: none;
  font-family: ${(props) => props.theme.fontFamilies.regular};
`;

export const WrapperRating = styled.div`
  @media (max-width: ${SIZES.mediumTablet}px) {
    padding: 20px 0;
    &:nth-child(1) {
      margin-right: 20px;
    }
  }
  width: 100%;
  padding: 20px 0;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid ${(props) => props.theme.palette.quaternary};
`;
export const WrapperRatingSubtitle = styled.div`
  @media (max-width: ${SIZES.mediumTablet}px) {
    border-bottom: unset;
  }
  @media (max-width: ${SIZES.bigPhone}px) {
    border-bottom: 1px solid ${(props) => props.theme.palette.quaternary};
  }

  width: 100%;
  padding-bottom: 20px;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid ${(props) => props.theme.palette.quaternary};
`;

export const Text = styled.p`
  font-size: 18px;
  line-height: 26px;
  color: ${(props) => props.theme.palette.common.grey};
  font-family: ${(props) => props.theme.fontFamilies.regular};
`;
export const BoldText = styled.p`
  @media (max-width: ${SIZES.bigPhone}px) {
  }
  display: contents;
  color: ${(props) => props.theme.palette.lightSecondary};
  font-family: ${(props) => props.theme.fontFamilies.bold};
`;

export const WrapperRatingInfo = styled.div`
  display: flex;
  align-items: center;
`;

export const WrapperImage = styled.div`
  width: 68px;
  height: 68px;
  padding-right: 26px;
`;

export const WrapperRatingText = styled.div`
  padding-right: 30px;
  width: 100%;
`;

export const ImageRating = styled(ReactSVG)`
  width: 100%;
  height: 100%;
`;

export const Subtitle = styled.p`
  font-size: 18px;
  line-height: 26px;
  color: ${(props) => props.theme.palette.common.grey};
  font-family: ${(props) => props.theme.fontFamilies.bold};
`;

export const WrapperTablet = styled.div`
  @media (max-width: ${SIZES.mediumTablet}px) {
    display: flex;
  }
  @media (max-width: ${SIZES.bigPhone}px) {
    display: block;
  }
`;
