import { CircularProgress } from '@mui/material';
import styled from 'styled-components';

import { ReactComponent as TableRows } from 'src/assets/sales/chartComparisson.svg';
import { ReactComponent as BarChart } from 'src/assets/sales/tableComparisson.svg';
import { SIZES } from 'src/helpers/devices';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin-bottom: 20px;
`;

export const Title = styled.p`
  font-size: 28px;
  color: ${(props) => props.theme.palette.lightSecondary};
  font-family: ${(props) => props.theme.fontFamilies.bold};
  margin-right: 5px;
`;

export const LoadingWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 500px;
`;

export const ContentWrapper = styled.div`
  min-height: 500px;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  background-color: ${(props) => props.theme.palette.common.white};
  box-shadow: ${(props) => props.theme.boxShadow.module};
  padding: 20px;
  box-sizing: border-box;
`;

export const LoadingIndicator = styled(CircularProgress)`
  color: ${(props) => props.theme.palette.lightSecondary} !important;
`;

export const HeaderWrapper = styled.div`
  @media (max-width: ${SIZES.mediumTablet}px) {
    flex-direction: column;
    align-items: baseline;
  }
  @media (max-width: ${SIZES.bigPhone}px) {
    flex-direction: row;
    align-items: flex-end;
  }
  display: flex;
  justify-content: space-between;
  border-bottom: ${(props) => `2px solid ${props.theme.palette.quaternary} `};
  align-items: center;
  margin-bottom: 20px;
`;

export const ModuleTitleWrapper = styled.div`
  flex: 1;
  padding-right: 5px;
`;

export const ModuleTitle = styled.p`
  font-size: 20px;
  line-height: 26px;
  color: ${(props) => props.theme.palette.common.grey};
  font-family: ${(props) => props.theme.fontFamilies.bold};
`;

export const ModuleSubtitle = styled.p`
  font-size: 16px;
  line-height: 26px;
  color: ${(props) => props.theme.palette.common.grey};
  font-family: ${(props) => props.theme.fontFamilies.regular};
  margin-bottom: 10px;
`;

export const TableIcon = styled(TableRows)`
  @media (max-width: ${SIZES.bigPhone}px) {
    margin-bottom: 10px;
  }
  height: 40px;
  width: 40px;
  color: ${(props) => props.theme.palette.lightSecondary};
  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.palette.quaternary};
  }
`;

export const ChartIcon = styled(BarChart)`
  @media (max-width: ${SIZES.bigPhone}px) {
    margin-bottom: 10px;
  }
  height: 40px;
  width: 40px;
  color: ${(props) => props.theme.palette.lightSecondary};
  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.palette.quaternary};
  }
`;
