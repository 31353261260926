import React, { useCallback, useEffect } from 'react';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import { useDispatch } from 'react-redux';
import { Redirect, Switch } from 'react-router-dom';

import * as appActions from 'src/redux/actions/app';

import history from 'src/helpers/history';

import PrivateRoute from './components/PrivateRoute';
import PublicRoute from './components/PublicRoute';
import BenchmarkContainer from './containers/Benchmark';
import ChangePasswordContainer from './containers/ChangePasswordContainer';
import ContactUsContainer from './containers/ContactUs';
import ExploreContainer from './containers/Explore';
import ForgotPasswordContainer from './containers/ForgotPassword';
import InsightsContainer from './containers/Insights';
import LoginContainer from './containers/Login';
import NewsContainer from './containers/News';
import NotFoundContainer from './containers/NotFound';
import SalesContainer from './containers/Sales';
import SignUpContainer from './containers/SignUp';
import SummaryContainer from './containers/Summary';
import { useTypedSelector } from './hooks/typeSelector';
import isUserLoggedSelector from './redux/selectors/user/isUserLogged';

const App = () => {
  const dispatch = useDispatch();
  const isUserLogged = useTypedSelector(isUserLoggedSelector());
  const handleRouteChange = useCallback(() => {
    dispatch(appActions.changeRoute(history.location.pathname));
  }, [dispatch]);

  useEffect(() => {
    handleRouteChange();
    const unListen = history.listen(handleRouteChange);
    return unListen;
  }, [handleRouteChange]);

  return (
    <>
      <ReactNotification />
      <Switch>
        <PublicRoute
          path="/login"
          component={LoginContainer}
          restricted={true}
          isUserLogged={isUserLogged}
        />
        <PublicRoute
          path="/sign-up"
          component={SignUpContainer}
          restricted={true}
          isUserLogged={isUserLogged}
        />
        <PublicRoute
          path="/404"
          component={NotFoundContainer}
          restricted={false}
          isUserLogged={isUserLogged}
        />
        <PublicRoute
          path="/password"
          component={ChangePasswordContainer}
          restricted={true}
          isUserLogged={isUserLogged}
        />
        <PublicRoute
          path="/forgot-password"
          component={ForgotPasswordContainer}
          restricted={true}
          isUserLogged={isUserLogged}
        />
        <PrivateRoute path="/summary" component={SummaryContainer} isUserLogged={isUserLogged} />
        <PrivateRoute path="/explore" component={ExploreContainer} isUserLogged={isUserLogged} />
        <PrivateRoute
          path="/benchmark"
          component={BenchmarkContainer}
          isUserLogged={isUserLogged}
        />
        <PrivateRoute path="/insights" component={InsightsContainer} isUserLogged={isUserLogged} />
        <PrivateRoute path="/news" component={NewsContainer} isUserLogged={isUserLogged} />
        <PrivateRoute path="/sales" component={SalesContainer} isUserLogged={isUserLogged} />
        <Redirect exact path="/" to="/login" />
        <Redirect path="*" to="/404" />
      </Switch>
      <ContactUsContainer />
    </>
  );
};

export default App;
