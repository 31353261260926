import styled from 'styled-components';

import { FOOTER_HEIGHT, HEADER_HEIGHT } from 'src/helpers/devices';

export const SalesWrapper = styled.div`
  background-color: ${(props) => props.theme.palette.septenary};
  min-height: calc(100vh - (${HEADER_HEIGHT}px + ${FOOTER_HEIGHT}px));
  overflow: auto;
`;

export const TargetSalesGrid = styled.div`
  display: grid;
  grid-gap: 40px;
  overflow: auto;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(3, auto);
  padding: 20px;
  grid-template-areas: 'headerSales' 'targetName' 'targetIndustry';
`;

export const SalesGrid = styled.div`
  display: grid;
  grid-gap: 40px;
  overflow: auto;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(3, auto);
  padding: 20px;
  grid-template-areas: 'headerSales' 'materialityRanking' 'howCompetitorPerform';
`;

export const HeaderWrapper = styled.div`
  grid-area: headerSales;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0 10px;
  border-bottom: 1px solid ${(props) => props.theme.palette.quinternary};
  line-height: 40px;
`;

export const MaterialityRankingWrapper = styled.div`
  grid-area: materialityRanking;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  background-color: ${(props) => props.theme.palette.common.white};
  box-shadow: ${(props) => props.theme.boxShadow.module};
`;

export const HowCompetitorPerformWrapper = styled.div`
  grid-area: howCompetitorPerform;
`;

export const MaterialityPerformanceWrapper = styled.div`
  width: 100%;
  padding: 0 20px 20px 20px;
  margin-top: 30px;
  box-sizing: border-box;
`;

export const MaterialityRankingModuleWrapper = styled.div`
  width: 100%;
  padding: 20px 20px 0 20px;
  box-sizing: border-box;
`;
