import { COLORS } from '../theme';

export const DefaultTheme = {
  palette: {
    common: {
      black: COLORS.black,
      white: COLORS.white,
      grey: COLORS.grey,
    },
    primary: COLORS.shadowGreen,
    secondary: COLORS.deepCoveBlue,
    lightSecondary: COLORS.lightCoveBlue,
    tertiary: COLORS.dodgerBlue,
    quaternary: COLORS.cornflowerBlue,
    quinternary: COLORS.borderBlue,
    sexternary: COLORS.borderLightBlue,
    septenary: COLORS.catskillWhite,
  },
  fontFamilies: {
    bold: 'europa-bold',
    boldItalic: 'europa-boldItalic',
    light: 'europa-light',
    lightItalic: 'europa-lightItalic',
    regular: 'europa-regular',
    regularItalic: 'europa-regularItalic',
  },
  fontSizes: {
    small: '12px',
    medium: '14px',
    large: '16px',
  },
  boxShadow: {
    module: `  0px 0px 12px rgba(174, 220, 222, 0.6)`,
  },
};

export type ITheme = typeof DefaultTheme;
