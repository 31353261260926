import { Paper, TextField } from '@mui/material';
import styled from 'styled-components';

interface IPathAdornment {
  adornmentPath: string;
}

export const SearchTextField = styled(TextField)`
  .MuiInputLabel-root {
    color: ${(props) => props.theme.palette.secondary};
    font-family: ${(props) => props.theme.fontFamilies.regular};
  }
  .MuiOutlinedInput-root {
    font-family: ${(props) => props.theme.fontFamilies.regular};
    line-height: 24px;
    color: ${(props) => props.theme.palette.secondary};
  }
  label.Mui-focused {
    color: ${(props) => props.theme.palette.secondary};
  }
  .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.palette.quinternary};
    border-radius: 15px;
  }
  &.internal-autofill-selected {
    background-color: red !important;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px ${(props) => props.theme.palette.common.white} inset !important;
  }
`;

export const DropdownBox = styled(Paper)`
  border-radius: 10px !important;
`;

export const DropdownOption = styled.li`
  font-size: 16px;
  color: ${(props) => props.theme.palette.common.grey};
  font-family: ${(props) => props.theme.fontFamilies.regular};
`;

export const IconSubmit = styled.div<IPathAdornment>`
  height: 15px;
  width: 15px;
  background-image: url(${(props) => props.adornmentPath});
  background-repeat: no-repeat;
  margin-right: 5px;
  cursor: pointer;
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
`;
