import i18next from 'i18next';

import { locales } from 'src/configs/i18n';

i18next.addResources(locales.EN, 'TABS_SCORE_EXPLORE_MODULE', {
  SOCIAL: 'Social',
  GOVERNANCE: 'Governance',
  ENVIRONMENT: 'Environment',
  LOAD_MORE: 'View more',
  HIDDEN_MORE: 'View less',
  'M-EE21-3': 'GHG Intensity',
  'M-EE21-2': 'Total GHG',
  'M-EE21-5': 'Scope 1 Emissions',
  'M-EE21-6': 'Scope 2 Emissions (Location)',
  'M-EE21-7': 'Scope 2 Emissions (Market)',
  'M-EE21-8': 'Scope 3 Emissions',
  'M-EE21-10': 'Total Scope 1 & 2 Emissions',
  'M-EE21-11': 'Scope 1 & 2 Emission Intensity',
  'M-EE37-2': 'Waste Intensity',
  'M-EE10-2': '% Total Waste Diverted (Recycling/Reuse)',
  'M-EE38-2': 'Hazardous Waste Intensity',
  'M-EE17-2': 'Water Pollutant Intensity',
  'M-EE17-4': 'Water Intensity',
  'M-EE102-2': 'Total Energy Intensity',
  'M-ES113-3': 'Managerial Positions Held by Women',
  'M-ES37-2': 'Mean Salary & Benefits',
  'M-ES31-1': 'Annual Employee Turnover',
  'M-ES7-2': 'Mean Annual Training',
  'M-ES5-2': 'Mean Annual Training Costs',
  'M-EG6-2': 'CEO or Highest Total Salary',
  'M-ES119-1': 'Lost Days Due to Injury',
  'M-ES117-1': 'Injury Intensity',
  'M-EG120-1': 'Number of Board Members Exceeding 10 or Below 8',
  'M-ES1-1': 'Number of Women on the Board',
  'M-ES1-2': 'Number of Board Members of Foreign Cultures',
  'M-EG124-1': 'Number of Board Meetings per Year',
  'M-EG27-2': 'Independent Board Members',
  'M-EG13-3': 'Independent Board Members on Audit Committee',
  'M-EG5-3': 'Independent Board Members on Compensation Committee',
  'M-EG126-2': 'Non-Executive Board Members',
  'M-EG5-6': 'Non-Executive Board Members on Nomination Committee',
  'M-EG6-4': 'Highest Renumeration Package',
  'M-EG33-3': 'Members of Nomination Committee Who Are Significant Shareholders',
  E_Score: '[IdealRatings ESG] Environment Score',
  S_Score: '[IdealRatings ESG] Social Score',
  G_Score: '[IdealRatings ESG] Governance Score',
  ESG_TS: 'IdealRatings ESG - Total Score',
  ESG_R: 'IdealRatings ESG - Rating',
  'YN US': 'UNGC Signatory',
  EU1: 'Supports Protection of Human Internationally Proclaimed Human Rights',
  EU2: 'Monitors for Human Rights Abuses',
  EU3: 'Supports Freedom of Association and Collective Bargaining',
  EU4: 'Supports Elimination of Forced or Compulsory Labor',
  EU5: 'Supports Abolition of Child Labor',
  EU6: 'Supports Elimination of Discrimination',
  EU7: 'Applies Precautionary Approach to Environment',
  EU8: 'Promotes Greater Environmental Responsibility',
  EU9: 'Development and Diffusion of Environmentally Friendly Technologies',
  EU10: 'Opposes Corruption in all Forms',
  EE1: 'Statement of Comprehensive Environment Policy',
  EE2: 'Committee Oversight of Environmental Protection',
  EE3: 'Renewable Energy Targets',
  EE5: 'Sourcing From Respsonsibly Managed Forests',
  EE6: 'Recycled or Recyclable Raw Materials',
  EE7: 'Sustainability Plans Integrated in Business Activities',
  EE8: 'Biodiversity Programs & Targets',
  EE9: 'Environmental Management System Certification',
  EE10: 'Waste Management Programs',
  EE12: 'Waste Recycling',
  EE13: 'Hazardous Waste Reduction Programs',
  EE14: 'Reports Oil Spills',
  EE15: 'Involved in Environmental Legal Proceedings',
  EE16: 'Water Management Strategy',
  EE17: 'Reports Water Management Data',
  EE18: 'Reports Water Recycling/Reuse',
  EE19: 'Reports Air Quality Data',
  EE20: 'Air Emissions Reduction Strategy',
  EE21: 'Reports GHG Data',
  EE22: 'Participates in Carbon Disclosure Project',
  EE24: 'Strategy to Reduce Building Emissions',
  EE25: 'Strategy to Reduce Corporate Vehicle Emissions',
  EE27: 'Active Stakeholder Engagement',
  EE28: 'Product Impact Policy',
  EE32: 'Reports Environmental Committee Results',
  EE34: 'Transparency in Environrnental Targets and Outcomes',
  EE35: 'Follows ISO14001 or EMAS Guidelines',
  EE36: 'Reports Costs of Environmental Improvements',
  EE37: 'Reports Waste Data',
  EE38: 'Reports Hazardous Waste Data',
  EE39: 'Environmental Grievance Mechanism',
  ES1: '≥ 20% of Directors are Women/Minorities',
  ES2: 'Training on Supplier Code of Conduct',
  ES3: '≥ 20% of Employees are Women/Minorities',
  ES4: 'Has Been Involved in Customer-Related Legal Proceedings',
  ES5: 'Provides Relevant Training to Employees',
  ES6: 'Access to Medicines Programs',
  ES7: 'Reports on Employee Training',
  ES8: 'Programs for Development of Medicines for Neglected Diseases',
  ES9: 'Policies for Elimination of Discrimination',
  ES10: 'Programs for Health Care Access',
  ES11: 'Programs to Support Supplier Diversity',
  ES12: 'Programs for Food Access',
  ES13: 'Involved in Human Rights Legal Proceedings',
  ES14: 'Programs for Education Access',
  ES15: 'Reports Workplace Accident Rates',
  ES16: 'Programs for Community Development',
  ES17: 'Workplace Safety Training',
  ES18: 'Promotes Financial Services Access for Disadvantaged Peoples',
  ES20: 'Policy on Customer Data Privacy',
  ES21: 'Respects Employee Right to Unionization',
  ES22: 'Policy on Supplier Data Privacy',
  ES23: 'Policy Against Forced Labor',
  ES24: 'Respects Employee Privacy',
  ES25: 'Health and Safety Certifications',
  ES26: 'Prior Informed Consent for Processing Personal Data',
  ES27: 'Involved in Employee Safety and Health Legal Proceedings',
  ES28: 'Supplier Code of Conduct',
  ES29: 'Reports on Employee Volunteering',
  ES30: 'Inspections of Suppliers',
  ES31: 'Reports Employee Turnover rate',
  ES32: 'Reports Inspections of Suppliers and Violations',
  ES33: 'Enforces Human Rights Policies',
  ES34: 'Fair Trade Certification',
  ES35: 'Employs Individuals with Disabilities',
  ES36: 'Has Been Involved in Supply Chain Legal Proceedings',
  ES37: 'Employee Compensation Policy',
  ES38: 'Provides/Supports Organic Foods',
  ES39: 'Uses Biological Fertilizers and Insecticides',
  ES40: 'Communicates Chemical Constituents in Products',
  ES41: 'Product Testing on Animals',
  ES42: "Abides by Country's Animal Testign Regulations",
  ES43: 'Product Safety and Quality Assurance',
  ES44: 'Quality Management System Certifications',
  ES45: 'Charitable Donatiosn in Excess of $67K',
  ES46: 'Child Labor Policy',
  ES47: 'Community Porgrams Headed by a Senior Manager',
  ES48: 'ILO Core Labor Standards',
  ES49: 'UN Global Compact / SA8000 / OECD Guideliens Signatory',
  ES51: 'Supports Universal Declaration of Human Rights',
  ES52: 'Voluntary Principles on Security and Human Rights Signatory',
  ES54: 'Human Rights Training for Employees',
  ES56: 'Monitors Performance on Human Rights Policy',
  ES19: 'Health aned Safety Incident Reduction Programs',
  ES63: 'Uses Animals in Product Manufacturing',
  ES64: 'Social Sustainability Plans Intergated in Business Activities',
  ES65: 'Work-Life Balance Policy',
  ES66: 'Provides Medical Insurance',
  ES67: 'Affordable Housing Access Programs',
  ES68: 'Prevents Third-Party Access to Persoanl Information',
  ES69: 'Measures Customer Satisfaction',
  ES70: 'Measures Employee Satisfaction',
  ES71: 'Social Grievance Mechanism',
  EG1: 'Anti-Corruption Policy',
  EG2: 'Anti-Corruption Training to Employees',
  EG4: 'Has Been Involved in Corruption Legal Proceedings',
  EG5: 'Committee to Recommend Board Nomination and Compensation',
  EG6: 'Reports on Director Remuneration',
  EG7: 'Publishes Sustainability Reports',
  EG8: 'Discloses Accounting Policies',
  EG9: 'IFRS or GAAP Financial Statements',
  EG10: 'Anti-Competitive/Anti-Trust Policies',
  EG11: 'Has Been Involved in Anti-Competitive Legal Proceedings',
  EG12: 'Separate CEO and Chairperson Roles',
  EG13: 'Audit and Risk Committee to Monitor Auditors',
  EG14: 'External Verification of CSR Reporting',
  EG15: 'In-house Team for Responsible Investment',
  EG16: 'Executive Compensation Tied to ESG',
  EG17: 'Statement on Responsible Marketing',
  EG19: 'Has Been Involved in Patent Infringement Legal Proceeedings',
  EG20: 'Anti-Corruption Audits',
  EG21: 'Whistle-Blower Mechanisms',
  EG22: 'Procedures for Bribery-Related Investigations',
  EG24: 'Anti-Corruption Policy Communiated to Employees',
  EG25: 'Chairperson in an Independent Director',
  EG26: 'All Members of Audit Committee Have Financial Background Expertise',
  EG27: 'Majority of Directors are Independent',
  EG29: 'Code of Ethics for Senior Executives',
  EG28: 'Formal Corporate Governance Policy',
  EG30: 'Political Contributions Policy',
  EG31: 'Insider Trading Policy',
  EG33: 'Directors Have Stock Ownership',
});
