import i18next from 'i18next';
import React from 'react';

import './i18n';
import {
  ExportButton,
  HelpIcon,
  WrapperButton,
  WrapperHelp,
  WrapperText,
  Title,
  Subtitle,
} from './styles';
import { IHeaderModuleProps } from './types';

const HeaderModule = (props: IHeaderModuleProps) => {
  const { handleOpenHelp, handleExportData } = props;

  return (
    <>
      <WrapperText>
        <Title data-testid={'title-explore-page'}>{i18next.t('HEADER_EXPLORE:TITLE')}</Title>
        <Subtitle data-testid={'subtitle-explore-page'}>
          {i18next.t('HEADER_EXPLORE:SUBTITLE')}
        </Subtitle>
        <WrapperHelp>
          <HelpIcon data-testid={'help-icon-explore-page'} onClick={handleOpenHelp} />
        </WrapperHelp>
      </WrapperText>
      <WrapperButton>
        <ExportButton data-testid={'button-export-explore-page'} onClick={handleExportData}>
          {i18next.t('HEADER_EXPLORE:BUTTON_EXPORT')}{' '}
        </ExportButton>
      </WrapperButton>
    </>
  );
};

export default HeaderModule;
