import { PillarScores } from 'src/enums/pillarScores';

export interface ICompanyGlobalScores {
  realScore: number;
  environmentScore: number;
  socialScore: number;
  governanceScore: number;
  GHCIntensity: number | null;
  wasteManagement: number | null;
  waterEfficiency: number | null;
  annualEmployedTurnover: number | null;
  renewableEnergyTargets: boolean | null;
  trainingCostPerEmployee: number | null;
  sustainabilityPlans: boolean | null;
  biodiversityConservation?: boolean | null;
  boardDirectorWoman?: boolean | null;
  minoritiesPercentage?: boolean | null;
  supplierCodeOfConduct?: boolean | null;
  providesTrainingOnSCC?: boolean | null;
  conductsInspectionsOfSuppliers?: boolean | null;
  monitorsImplementationHumanPolicies?: boolean | null;
  executiveCompensationLinkedESG?: boolean | null;
  totalScopeEmissions?: number;
  participatesInCarbonDisclosureProject?: boolean;
  hasAWaterManagementStrategy?: boolean;
  employsWaterRecyclingOrReuse?: boolean;
  hasAnEnvironmentalProtectionOrPolicyCommittee?: boolean;
  signatoryToUnGlobalCompact?: boolean;
  hazardousWasteIntensity?: number;
  followsIso14001OrEcmasGuidelines?: boolean;
  recycledOrRecyclableRawMaterials?: boolean;
  statementOfComprehensiveEnvironmentPolicy?: boolean;
  totalEnergyIntensity?: number;
  reportsOnCommunityDevelopmentPrograms?: boolean;
  hasProgramsForEducationAccess?: boolean;
  hasProgramsForHealthCareAccess?: boolean;
  lostDaysDueToInjury?: number;
  injuryIntensity?: number;
  managerialPositionsHeldByWomen?: number;
  voluntaryPrinciplesOnSecurityAndHumanRightsSignatory?: boolean;
  supportsUniversalDeclarationOfHumanRights?: boolean;
  humanRightsTrainingForEmployees?: boolean;
  policyOnCustomerDataPrivacy?: boolean;
  policyOnSupplierDataPrivacy?: boolean;
  preventsThirdPartyAccessToPersonalInformation?: boolean;
  externalVerificationOfCsrOrEsgReporting?: boolean;
  qualityManagementSystemCertifications?: boolean;
  productSafetyAndQualityAssuranceInitiatives?: boolean;
  communicatesChemicalConstituentsInProducts?: boolean;
  wasteIntensity?: number;
}

export interface IProviderScore {
  id: string;
  scoreName: string;
  score: string;
}

export enum ScoreProviders {
  SP = 'S&P',
  SUSTAINALYTICS = 'Sustainalytics',
  ARABESQUE = 'Arabesque',
  REFINITIV = 'Refinitiv',
  MSCI = 'MSCI',
  CDP = 'CDP',
  ISS = 'ISS',
}

export interface ICompanyScore {
  provider: ScoreProviders;
  scores: IProviderScore[];
}

export interface IIndustryRank {
  position: number;
  total: number;
}

export interface IIndustryScores {
  pillar: PillarScores;
  industryScores: (number | boolean)[];
}

export interface IScoresByIndustry {
  industry: string;
  data: IIndustryScores[];
}

export interface IPositiveMateriality {
  industry: string;
  positiveMateriality: string[];
}
