import { DataGroup } from 'src/enums/data';

import { get } from './api';

export const getData = (group: DataGroup, hierarchy: number) => {
  return get('data/company/me', {
    group,
    hierarchy,
  });
};

export const getUserMateriality = (industry: string) => {
  return get(`data/industry/materiality?industry=${industry}`);
};
