import styled from 'styled-components';

import { FOOTER_HEIGHT, HEADER_HEIGHT, SIZES } from 'src/helpers/devices';

export const ExploreWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.palette.septenary};
  padding: 20px;
  min-height: calc(100vh - (${HEADER_HEIGHT}px + ${FOOTER_HEIGHT}px));
`;

export const GridWrapper = styled.div`
  @media (max-width: ${SIZES.desktop}px) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, auto);
    grid-template-areas:
      'header'
      'realScore'
      'ratingCoverge'
      'scores';
  }
  width: 100%;
  display: grid;
  overflow: auto;
  grid-gap: 20px;
  grid-template-columns: 312px auto auto;
  grid-template-rows: repeat(3, auto);
  grid-template-areas:
    'header header header'
    'realScore ratingCoverge ratingCoverge'
    'scores scores scores';
`;

export const HeaderWrapper = styled.div`
  grid-area: header;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0 10px;
  border-bottom: 1px solid ${(props) => props.theme.palette.quinternary};
  line-height: 40px;
`;

export const Title = styled.p`
  width: 100%;
  text-align: center;
  font-size: 28px;
  line-height: 34px;
  color: ${(props) => props.theme.palette.secondary};
  font-family: ${(props) => props.theme.fontFamilies.bold};
`;

export const Subtitle = styled.p`
  width: 100%;
  text-align: center;
  font-size: 16px;
  padding-top: 20px;
  color: ${(props) => props.theme.palette.secondary};
  font-family: ${(props) => props.theme.fontFamilies.regular};
`;

export const RealScoreWrapper = styled.div`
  grid-area: realScore;
`;

export const RatingCoverageWrapper = styled.div`
  grid-area: ratingCoverge;
`;

export const ScoresWrapper = styled.div`
  grid-area: scores;
  margin-top: 20px;
`;

export const AnimationWrapper = styled.div`
  text-align: center;
  height: 250px;
  display: flex;
  justify-content: center;
  align-self: center;
  align-items: center;
  margin: 20px 0;
`;
