import styled from 'styled-components';

import { FOOTER_HEIGHT, HEADER_HEIGHT } from 'src/helpers/devices';

export const SalesWrapper = styled.div`
  background-color: ${(props) => props.theme.palette.septenary};
  min-height: calc(100vh - (${HEADER_HEIGHT}px + ${FOOTER_HEIGHT}px));
  overflow: auto;
`;

export const TargetNameWrapper = styled.div`
  grid-area: targetName;
`;

export const TargetIndustryWrapper = styled.div`
  grid-area: targetIndustry;
`;

export const TargetNameText = styled.div`
  font-size: 20px;
  line-height: 20px;
  color: ${(props) => props.theme.palette.lightSecondary};
  font-family: ${(props) => props.theme.fontFamilies.bold};
  margin-right: 5px;
`;

export const TargetIndustryText = styled.div`
  font-size: 18px;
  line-height: 28px;
  color: ${(props) => props.theme.palette.common.grey};
  font-family: ${(props) => props.theme.fontFamilies.regular};
  margin-right: 5px;
`;

export const WrapperText = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const WrapperInput = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const WrapperAutcomplete = styled.div`
  width: 329px;
  height: 48px;
`;

export const HelpSubmitText = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  font-size: 18px;
  line-height: 28px;
  color: ${(props) => props.theme.palette.common.grey};
  font-family: ${(props) => props.theme.fontFamilies.regular};
  margin-right: 5px;
`;
