import i18next from 'i18next';

import { locales } from 'src/configs/i18n';

i18next.addResources(locales.EN, 'EXPLORE', {
  TITLE_MODAL_EXPORT: 'Download explore',
  BUTTON_EXPORT: 'DOWNLOAD',
  MOBILE_TITLE: 'This section is not supported in mobile resolution.',
  MOBILE_SUBTITLE: 'Please login using a desktop resolution to browse this section.',
  MESSAGE_MODAL_EXPLANIER:
    'In this section, we provide key metrics based on data the ESG rating agencies are collecting on you. Harness this opportunity to peer into broader market perceptions and compare your performance against your competitors, partners, and industry peers.',
});
