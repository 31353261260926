import segmentAnalytics from '@analytics/segment';
import Analytics from 'analytics';

import { APP_ENV, SEGMENT_ANALYTICS_KEY } from 'src/configs/configs';
import { PillarScores } from 'src/enums/pillarScores';
import IUser from 'src/types/user';

import { pillarScoreTexts } from './pillarsScores';

const analytics = Analytics({
  app: `Motive ${APP_ENV}`,
  plugins: [
    segmentAnalytics({
      writeKey: SEGMENT_ANALYTICS_KEY,
    }),
  ],
});

export const trackEvent = (name: string, metadata: any = {}) => {
  analytics.track(name, metadata);
};

export const trackPageView = (location: string) => {
  analytics.page({
    url: location,
    title: location,
  });
};

export const identify = (user: IUser) => {
  analytics.identify(user.id.toString(), {
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    company: user.company.name,
  });
};

export const trackHelpModule = (
  currentRoute: string,
  currentModule: string,
  isNext: boolean,
  user: IUser,
) => {
  if (currentModule === undefined) {
    trackEvent(`Tutorial ${currentRoute} open`, {
      user: {
        name: `${user.firstName} ${user.lastName}`,
        email: user.email,
      },
      company: user.company.name,
    });
    return;
  }
  trackEvent(`Tutorial ${isNext ? 'next' : 'back'}`, {
    user: {
      name: `${user.firstName} ${user?.lastName}`,
      email: user.email,
    },
    company: user.company.name,
    currentModule,
  });
};

export const trackFilteredPillarsScores = (
  pillarScoresToTrack: PillarScores[],
  allFilteredPillarsScoreSettings: PillarScores[],
  isRemovePillarScore: boolean,
  user: IUser,
) => {
  const pillarScoreName = pillarScoresToTrack.map((element) => {
    return pillarScoreTexts.find((pillar) => pillar.value === element)?.text;
  });
  const allPillarsScore = allFilteredPillarsScoreSettings.map((element) => {
    return pillarScoreTexts.find((pillar) => pillar.value === element)?.text;
  });
  trackEvent(isRemovePillarScore ? 'Remove pillar score to table' : 'Add pillar score to table', {
    user: {
      name: `${user.firstName} ${user.lastName}`,
      email: user.email,
    },
    company: user.company.name,
    currentPillarScoreTable: allPillarsScore,
    changedPillarScore: pillarScoreName,
  });
};

export const reset = () => analytics.reset();

export default analytics;
