import percentage from 'calculate-percentages';
import i18next from 'i18next';
import React from 'react';

import { pillarScoreTexts, sortIndustryScores } from 'src/helpers/pillarsScores';
import {
  formatScoreByPillar,
  formatValueScoreByPillar,
  getAverageScore,
  getUnitScoreByPillar,
} from 'src/helpers/scores';

import './i18n';
import {
  Description,
  Breakdown,
  ContentWrapper,
  HeaderWrapper,
  IndustryBreakdownWrapper,
  PillarDetailsWrapper,
  PillarName,
  PillarRank,
  PillarIndustries,
  PillarRankWrapper,
} from './styles';
import { IPillarDetailsProps } from './types';

const PillarDetails = (props: IPillarDetailsProps) => {
  const { pillarMetadata, industryScores, companyScore } = props;

  const sortedIndustryScores = sortIndustryScores(industryScores, pillarMetadata).filter(
    (score) => score !== null,
  );

  const percentageBestCompanies = percentage.calculate(
    industryScores.filter((score) => score === true && score !== null).length,
    industryScores.filter((score) => score !== null).length,
  );
  const percentageWorstCompanies = percentage.calculate(
    industryScores.filter((score) => score === false && score !== null).length,
    industryScores.filter((score) => score !== null).length,
  );

  return (
    <PillarDetailsWrapper>
      <HeaderWrapper>
        <PillarName>
          {pillarScoreTexts.find((pillar) => pillar.value === pillarMetadata.Identifier)!.text}
        </PillarName>
        {companyScore && (
          <PillarRankWrapper>
            {pillarMetadata.ValueType === 'boolean' && (
              <PillarRank>
                {formatScoreByPillar(pillarMetadata.Identifier, companyScore)}
              </PillarRank>
            )}
            {pillarMetadata.ValueType === 'number' && (
              <>
                <PillarRank>
                  {sortedIndustryScores.findIndex((score) => score === companyScore) + 1}
                </PillarRank>
                <PillarIndustries>
                  /{industryScores.filter((score) => score !== null).length}
                </PillarIndustries>
              </>
            )}
          </PillarRankWrapper>
        )}
        {!companyScore && (
          <PillarRankWrapper>
            {pillarMetadata.ValueType === 'number' && (
              <>
                <PillarRank>
                  {formatValueScoreByPillar(
                    pillarMetadata.Identifier,
                    getAverageScore(sortedIndustryScores, pillarMetadata.ValueType),
                  )}
                </PillarRank>
                <PillarName>
                  {getAverageScore(sortedIndustryScores, pillarMetadata.ValueType)?.toString() !==
                  'N/A'
                    ? getUnitScoreByPillar(pillarMetadata.Identifier)
                    : ''}
                </PillarName>
              </>
            )}
            {pillarMetadata.ValueType === 'boolean' && (
              <PillarRank>
                {formatScoreByPillar(
                  pillarMetadata.Identifier,
                  getAverageScore(sortedIndustryScores, pillarMetadata.ValueType),
                )}
              </PillarRank>
            )}
          </PillarRankWrapper>
        )}
      </HeaderWrapper>
      <>
        {pillarMetadata.ValueType === 'number' && (
          <>
            {sortedIndustryScores.every((score) => score === null) ? (
              <Description>{i18next.t(`PILLAR_DETAILS:PILLAR_NOT_APPLY`)}</Description>
            ) : (
              <ContentWrapper>
                <IndustryBreakdownWrapper>
                  <Description>
                    {i18next.t(`PILLAR_DETAILS:BEST_COMPANY_${pillarMetadata.ValueType}`)}
                  </Description>
                  <Breakdown>
                    {formatValueScoreByPillar(pillarMetadata.Identifier, sortedIndustryScores[0])}
                  </Breakdown>
                </IndustryBreakdownWrapper>
                <IndustryBreakdownWrapper>
                  <Description>
                    {i18next.t(`PILLAR_DETAILS:WORST_COMPANY_${pillarMetadata.ValueType}`)}
                  </Description>
                  <Breakdown>
                    {formatValueScoreByPillar(
                      pillarMetadata.Identifier,
                      sortedIndustryScores[sortedIndustryScores.length - 1],
                    )}
                  </Breakdown>
                </IndustryBreakdownWrapper>
              </ContentWrapper>
            )}
          </>
        )}
        {pillarMetadata.ValueType === 'boolean' && (
          <>
            {sortedIndustryScores.every((score) => score === null) ? (
              <ContentWrapper>
                <IndustryBreakdownWrapper>
                  <Description>{i18next.t(`PILLAR_DETAILS:NOT_APLICABLE`)}</Description>
                  <Breakdown>{i18next.t(`PILLAR_DETAILS:VALUE_NOT_APLICABLE`)}</Breakdown>
                </IndustryBreakdownWrapper>
              </ContentWrapper>
            ) : (
              <ContentWrapper>
                <IndustryBreakdownWrapper>
                  <Description>
                    {i18next.t(`PILLAR_DETAILS:BEST_COMPANY_${pillarMetadata.ValueType}`)}
                  </Description>
                  <Breakdown>{percentageBestCompanies.toFixed(2) + '%'}</Breakdown>
                </IndustryBreakdownWrapper>
                <IndustryBreakdownWrapper>
                  <Description>
                    {i18next.t(`PILLAR_DETAILS:WORST_COMPANY_${pillarMetadata.ValueType}`)}
                  </Description>
                  <Breakdown>{percentageWorstCompanies.toFixed(2) + '%'}</Breakdown>
                </IndustryBreakdownWrapper>
              </ContentWrapper>
            )}
          </>
        )}
      </>{' '}
    </PillarDetailsWrapper>
  );
};

export default PillarDetails;
