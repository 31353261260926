import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import * as dataActions from 'src/redux/actions/data';
import * as userActions from 'src/redux/actions/user';
import industryRankSelector from 'src/redux/selectors/company/industryRank';
import companyDataSelector from 'src/redux/selectors/data/companyData';
import settingsSelector from 'src/redux/selectors/user/settings';
import userDataSelector from 'src/redux/selectors/user/userData';

import { DataGroup } from 'src/enums/data';
import { useTypedSelector } from 'src/hooks/typeSelector';
import Explore from 'src/views/Explore';

import LayoutContainer from './Layout';

const ExploreContainer = () => {
  const dispatch = useDispatch();
  const industryRank = useTypedSelector(industryRankSelector());
  const userSettings = useTypedSelector(settingsSelector());
  const user = useTypedSelector(userDataSelector());
  const data = useTypedSelector(companyDataSelector());

  const handleTrackEvent = (event: string) => {
    dispatch(userActions.onTrackEvent(event));
  };

  const onMoreData = (group: DataGroup, hierarchy: number) => {
    dispatch(dataActions.onMoreDataRequested(group, hierarchy));
  };

  const onClickDontShowAgain = (isChecked: boolean) => {
    dispatch(userActions.onChangeSettings({ ...userSettings, dontShowModalExplore: isChecked }));
  };

  const onInitializeExploreView = useCallback(() => {
    dispatch(userActions.initializeExploreView());
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
    onInitializeExploreView();
  }, [onInitializeExploreView]);

  return (
    <LayoutContainer>
      <Explore
        scores={user?.company.lastUpdatedScore || null}
        userSettings={userSettings}
        data={data}
        industryRank={industryRank}
        ratingCoverage={user?.company.scores || []}
        onMoreData={onMoreData}
        handleTrackEvent={handleTrackEvent}
        onClickDontShowAgain={onClickDontShowAgain}
      />
    </LayoutContainer>
  );
};

export default ExploreContainer;
