import { AnyAction } from 'redux';

import * as dataConstants from 'src/redux/constants/data';
import * as userConstants from 'src/redux/constants/user';

import { IDataItem } from 'src/helpers/types/data';

export interface IDataReducerState {
  companyData: IDataItem[];
}

const defaultState: IDataReducerState = {
  companyData: [],
};

const setCompanyData = (state: IDataReducerState, companyData: IDataItem[]) => ({
  ...state,
  companyData,
});

const addCompanyData = (state: IDataReducerState, newData: IDataItem[]) => {
  const dataToAdd = newData.filter(
    (item) =>
      !state.companyData.find((existingItem) => existingItem.identifier === item.identifier),
  );
  return {
    ...state,
    companyData: [...state.companyData, ...dataToAdd],
  };
};

const dataReducer = (state = defaultState, action: AnyAction) => {
  switch (action.type) {
    case userConstants.USER_ON_INITIALIZE_EXPLORE_VIEW_SUCCEEDED:
      return setCompanyData(state, action.data);
    case dataConstants.DATA_ON_LOAD_MORE_SUCCEEDED:
      return addCompanyData(state, action.data);
    default:
      return state;
  }
};

export default dataReducer;
