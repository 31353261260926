import i18next from 'i18next';
import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import LottiePlayer from 'src/components/LottiePlayer';
import Modal from 'src/components/Modal';
import ModalExplainer from 'src/components/ModalExplainer';
import { SIZES } from 'src/helpers/devices';
import { ScoreProviders } from 'src/types/score';

import AnimationPath from '../../animation/explore/Motive-icon-explore-mobile.json';
import ModalExport from '../../components/ModalExport';
import Header from './components/Header';
import Help from './components/Help';
import RatingCoverageModule from './components/RatingCoverage';
import RealScoreModule from './components/RealScore';
import TabsScoresModule from './components/TabsScores';
import './i18n';
import {
  ExploreWrapper,
  HeaderWrapper,
  RatingCoverageWrapper,
  RealScoreWrapper,
  ScoresWrapper,
  Subtitle,
  Title,
  GridWrapper,
  AnimationWrapper,
} from './styles';
import { IExploreProps } from './types';

const Explore = (props: IExploreProps) => {
  const {
    industryRank,
    ratingCoverage,
    scores,
    data,
    userSettings,
    onMoreData,
    handleTrackEvent,
    onClickDontShowAgain,
  } = props;
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [isHelpOpen, setIsHelpOpen] = useState(false);
  const [isOpenModalModalExplainer, setIsOpenModalModalExplainer] = useState(true);

  const isTablet = useMediaQuery({
    query: `(max-width: ${SIZES.mediumTablet}px)`,
  });

  const handleOpenHelp = () => {
    setIsHelpOpen(true);
    handleTrackEvent('Open Explore help');
  };

  const handleCloseHelp = () => {
    setIsHelpOpen(false);
    handleTrackEvent('Close Explore help');
  };

  const handleCloseModalExport = () => {
    setIsExportModalOpen(false);
  };

  const handleExportData = () => {
    handleTrackEvent('Export explore open');
    setIsExportModalOpen(true);
  };

  const handleCloseModalExplainer = () => {
    setIsOpenModalModalExplainer(false);
  };

  return (
    <ExploreWrapper>
      <Modal open={isHelpOpen} onClose={handleCloseHelp} width={773}>
        <Help />
      </Modal>
      <ModalExport
        open={isExportModalOpen}
        title={i18next.t('EXPLORE:TITLE_MODAL_EXPORT')}
        onClose={handleCloseModalExport}
      />
      {!userSettings.dontShowModalExplore && (
        <ModalExplainer
          onClose={handleCloseModalExplainer}
          open={isOpenModalModalExplainer}
          message={i18next.t('EXPLORE:MESSAGE_MODAL_EXPLANIER')}
          onClickDontShowAgain={onClickDontShowAgain}
        />
      )}
      {isTablet && (
        <>
          <AnimationWrapper>
            <LottiePlayer width={500} animationJsonPath={AnimationPath} loop autoplay />
          </AnimationWrapper>
          <Title>{i18next.t('EXPLORE:MOBILE_TITLE')}</Title>
          <Subtitle>{i18next.t('EXPLORE:MOBILE_SUBTITLE')}</Subtitle>{' '}
        </>
      )}
      {!isTablet && scores && (
        <>
          <HeaderWrapper>
            <Header handleExportData={handleExportData} handleOpenHelp={handleOpenHelp} />
          </HeaderWrapper>
          <GridWrapper>
            <RealScoreWrapper>
              <RealScoreModule industryRank={industryRank} realScore={scores.realScore} />
            </RealScoreWrapper>
            <RatingCoverageWrapper>
              {ratingCoverage && (
                <RatingCoverageModule
                  scores={ratingCoverage.filter((score) => score.provider !== ScoreProviders.CDP)}
                />
              )}
            </RatingCoverageWrapper>
            <ScoresWrapper>
              <TabsScoresModule
                data={data}
                scores={scores}
                onMoreData={onMoreData}
                handleTrackEvent={handleTrackEvent}
              />
            </ScoresWrapper>
          </GridWrapper>
        </>
      )}
    </ExploreWrapper>
  );
};

export default Explore;
