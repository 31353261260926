import { DataGroup } from 'src/enums/data';
import { IDataItem } from 'src/helpers/types/data';

import * as dataConstants from '../constants/data';

export const onMoreDataRequested = (group: DataGroup, hierarchy: number) => ({
  type: dataConstants.DATA_ON_LOAD_MORE_REQUESTED,
  group,
  hierarchy,
});

export const onMoreDataSucceded = (data: IDataItem[]) => ({
  type: dataConstants.DATA_ON_LOAD_MORE_SUCCEEDED,
  data,
});

export const onMoreDataFailed = (errorMessage: string) => ({
  type: dataConstants.DATA_ON_LOAD_MORE_FAILED,
  errorMessage,
});
