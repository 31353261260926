import { TabsListUnstyled, TabUnstyled, tabUnstyledClasses } from '@mui/base';
import { CircularProgress } from '@mui/material';
import { ReactSVG } from 'react-svg';
import styled from 'styled-components';

import { SIZES } from 'src/helpers/devices';

import { ITabProps } from './types';

export const IconScore = styled(ReactSVG)`
  width: 100%;
`;
export const Tab = styled(TabUnstyled)<ITabProps>`
  width: 31%;
  padding: 0 20px;
  min-height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${(props) => props.theme.palette.common.grey};
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border: unset;
  color: ${(props) => props.theme.palette.common.white};
  cursor: pointer;
  box-shadow: ${(props) => props.theme.boxShadow.module};
  ${(props) =>
    !props.isSelected &&
    `
  box-shadow: inset 0px -10px 20px rgba(44, 68, 92, 0.36);
  `}
  &.${tabUnstyledClasses.selected} {
    background-color: ${(props) => props.theme.palette.common.white};
    color: ${(props) => props.theme.palette.secondary};
  }
`;

export const TabList = styled(TabsListUnstyled)`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const WrapperIconScore = styled.div`
  width: 30px;
  height: 30px;
`;

export const WrapperText = styled(TabsListUnstyled)`
  width: 100%;
  padding-left: 10px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
`;
export const Text = styled.p`
  font-size: 19px;
  line-height: 18px;
  font-family: ${(props) => props.theme.fontFamilies.bold};
`;
export const ValueScore = styled.p`
  font-size: 30px;
  line-height: 30px;
  font-family: ${(props) => props.theme.fontFamilies.bold};
`;

export const WrapperTable = styled.div`
  width: 100%;
  min-height: 300px;
  background-color: ${(props) => props.theme.palette.common.white};
  box-shadow: ${(props) => props.theme.boxShadow.module};
  border-radius: 0 0 16px 16px;
  position: relative;
  z-index: 3;
  padding: 20px;
  box-sizing: border-box;
`;

export const WrapperContent = styled.div`
  @media (max-width: ${SIZES.smallDesktop}px) {
    display: flex;
    flex-direction: column;
  }
  width: 100%;
  min-height: 200px;
  border-radius: 10px;
  padding: 20px;
  box-sizing: border-box;
  background-color: ${(props) => props.theme.palette.septenary};
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const WrapperLoadMore = styled.div`
  width: 100%;
  padding-top: 20px;
  border-top: 2px solid ${(props) => props.theme.palette.septenary};
`;

export const LoadMore = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${(props) => props.theme.fontFamilies.regular};
  font-size: 16px;
  line-height: 20px;
  color: ${(props) => props.theme.palette.tertiary};
  cursor: pointer;
`;

export const ValueData = styled.p`
  font-family: ${(props) => props.theme.fontFamilies.bold};
  color: ${(props) => props.theme.palette.lightSecondary};
  font-size: 24px;
  line-height: 40px;
`;

export const LabelData = styled.p`
  font-family: ${(props) => props.theme.fontFamilies.regular};
  color: ${(props) => props.theme.palette.lightSecondary};
  font-size: 16px;
  line-height: 20px;
  max-width: 330px;
  align-self: center;
`;

export const WrapperData = styled.div`
  @media (max-width: ${SIZES.smallDesktop}px) {
    width: 100%;
    box-sizing: border-box;
  }
  width: 45%;
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
`;

export const WrapperMoreData = styled.div`
  @media (max-width: ${SIZES.smallDesktop}px) {
    width: 100%;
    box-sizing: border-box;
  }
  width: 45%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${(props) => props.theme.palette.septenary};
  padding: 10px 0;
  align-items: center;
`;

export const WrapperContentMoreData = styled.div`
  width: 100%;
  border-radius: 10px;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const LoadingIndicator = styled(CircularProgress)`
  color: ${(props) => props.theme.palette.lightSecondary} !important;
`;

export const LoadingMoreDataIndicator = styled(CircularProgress)`
  color: ${(props) => props.theme.palette.lightSecondary} !important;
`;

export const LoadingWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
`;
